import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useRef, useState, useEffect } from 'react';
import About from '../components/about';
import CardGrid from '../components/card-grid';
import Contact from '../components/contact';
import FeaturedProjects from '../components/featured-projects';
import Hero from '../components/hero';
import Facts from '../components/facts';
import Layout from '../components/layout';
import RecentPosts from '../components/recent-posts';
import SEO from '../components/seo';
import {Helmet} from "react-helmet";
import { indexMenuLinks } from '../components/_config/menu-links';
import { Canvas, useFrame } from '@react-three/fiber';
import { window, document } from 'browser-monads';

const Index = ({ data }) => {
  const heroData = {
    author: data.site.siteMetadata.author,
    tagline: data.hero.frontmatter.tagline,
    description: data.hero.html,
    introduction: data.hero.frontmatter.introduction,
    ctaLabel: data.hero.frontmatter.cta_label,
    ctaLink: data.hero.frontmatter.cta_link,
  };

  return (
    <Layout id={'home'} menuLinks={indexMenuLinks}>
      <SEO title="Home" />
      <Hero data={heroData} />
      {/* <Facts/> */}
      <About data={data.about} />
      {/* <CardGrid cards={data.cards.frontmatter.cards} description={data.cards.html} title="Our Features" id="features" /> */}
      <FeaturedProjects featured={data.featuredProjects.nodes} />
      {/* <RecentPosts data={data.blog.edges} /> */}
      <Contact data={data.contact} />
      <Canvas style={{height: '100vh', position: 'absolute', zIndex: -1, top: 0}}>
        <ambientLight />
        <pointLight position={[10, 10, 10]} />
        <Torus position={[2, 0, 0.5]} rY={-0.025} rX={0.001} />
      </Canvas>
    </Layout>
  );
};

function Box(props) {

  // This reference will give us direct access to the mesh
  const mesh = useRef()
  // Set up state for the hovered and active state
  const [hovered, setHover] = useState(false)
  const [active, setActive] = useState(false)
  // Rotate mesh every frame, this is outside of React without overhead
  useFrame(() => {
    mesh.current.rotation.x += props.rX;
    if (typeof window !== `undefined`) {
      window.onscroll = () => {
        mesh.current.position.z = window.pageYOffset * -0.005 + 0.5;
        mesh.current.rotation.z = window.pageYOffset * -0.001 + 0.5;
        console.log(window.pageYOffset);
      }
    }
  });

  return (
    <mesh
      {...props}
      ref={mesh}
      // scale={active ? 1.5 : 1}
      onClick={(event) => setActive(!active)}
      onPointerOver={(event) => setHover(true)}
      onPointerOut={(event) => setHover(false)}>
      <boxGeometry args={[1, 2, 3]} />
      <meshStandardMaterial color={hovered ? 'hotpink' : '#005249'} />
    </mesh>
  )
}

function Torus(props) {

  // This reference will give us direct access to the mesh
  const mesh = useRef();

  // Rotate mesh every frame, this is outside of React without overhead
  useFrame(() => {
    mesh.current.rotation.y += props.rY;
    mesh.current.rotation.x += props.rX;
    if (typeof window !== `undefined`) {
      window.onscroll = () => {
        mesh.current.position.z = window.pageYOffset * -0.0075 + 0.5;
        mesh.current.rotation.z = window.pageYOffset * -0.001 + 0.5;
      }
    }
  });

  return (
    <mesh
      {...props}
      ref={mesh}>
      <torusGeometry args={[1, 0.4, 16, 100]} />
      <meshStandardMaterial color={'#005249'} />
    </mesh>
  )
}

Index.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Index;

export const query = graphql`
  {
    site {
      siteMetadata {
        author
      }
    }

    hero: markdownRemark(fileAbsolutePath: { regex: "/content/sections/hero/" }) {
      frontmatter {
        introduction
        tagline
        cta_label
        cta_link
      }
      html
    }

    about: markdownRemark(fileAbsolutePath: { regex: "/content/sections/about/" }) {
      frontmatter {
        title
        techs
        about_image {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      html
    }

    cards: markdownRemark(fileAbsolutePath: { regex: "/content/sections/cards/" }) {
      frontmatter {
        cards {
          label
          icon
          prefix
          description
        }
      }
      html
    }

    featuredProjects: allMarkdownRemark(
      limit: 3
      sort: { order: DESC, fields: frontmatter___date }
      filter: { fileAbsolutePath: { regex: "/content/projects/" }, frontmatter: { featured: { eq: true } } }
    ) {
      nodes {
        frontmatter {
          date(formatString: "D MMMM, YYYY")
          title
          repo_link
          demo_link
          blog_link
          techs
          cover_image {
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        html
      }
    }

    blog: allMarkdownRemark(
      sort: { order: DESC, fields: frontmatter___date }
      limit: 2
      filter: { fileAbsolutePath: { regex: "/content/posts/" }, frontmatter: { published: { ne: false } } }
    ) {
      edges {
        node {
          frontmatter {
            title
            tags
            date(formatString: "D MMMM, YYYY")
            description
            cover_image {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          excerpt
          fields {
            slug
          }
        }
      }
    }

    contact: markdownRemark(fileAbsolutePath: { regex: "/content/sections/contact/" }) {
      frontmatter {
        phone
        email
        address
      }
      html
    }
  }
`;
